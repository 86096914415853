import { rhythm } from "./typography"

// TODO: Merge this into the Mui theme
const theme = {
  contentWidth: rhythm(55),
  contentWidthReading: rhythm(40),
  contentPaddingHorizontal: rhythm(2 / 3),
  navbarHeight: `80px`,
  navbarHeightMobile: `50px`,
  footerHeight: rhythm(5),
  colors: {
    gradients: {
      blue1: `linear-gradient(to right, #00338d, #10409c, #1d4dac, #275abb, #3168cb)`,
      orange: `linear-gradient(to right, #ebb700, #eca500, #ec9300, #ea8108, #e76f14)`,
      grey1: `linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)`,
      grey2: `linear-gradient(45deg, #09203f 0%, #537895 100%)`,
    },
    green: "#33cc6d",
    green2: "#cce6cb",
    green3: "#3a753a",
    grey: "#CCCCCC",
    white1: "#FDFCFB",
    white_dimmed: "#ddd",
    blue1: "#8AA9DE",
    blue5: "#B2BDD0",
    blue6: "#E6E9F0",
    blue7: "#EEF1F5",
    blue8: "#00c6fb",
    blue9: "#005bea",
    blue2: "#D9E6FF",
    blue3: "#2A5298", // gradient start
    blue4: "#1E3C72", // gradient end
    lci_blue: "#00338D",
    lci_cool_gray: "#747678",
    lci_gray: "#55565A",
    lci_purple: "#7A2582",
    lci_yellow: "#EBB700",
    lci_red: "#FF5B35",
    yellow1: "#FFCF01",
  },
  colors_rgba: {
    black: "rgba(0,0,0,0.87)",
    white_medium: "rgba(255,255,255,0.9)",
    black_medium: "rgba(0,0,0,0.6)",
    black_low: "rgba(0,0,0,0.38)",
    lci_cool_gray_30: "rgba(116,118,120,0.3)",
  },
  breakpoints: {
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200,
  },
  burgerMenuBreakpoint: "large",
}

export default theme
