import Typography from "typography"

const fonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
]

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHeight: 1.4,
  headerFontFamily: fonts,
  bodyFontFamily: fonts,
  includeNormalize: false,
  overrideStyles: () => ({
    html: {
      overflowY: `initial`,
      overflowX: `initial`,
    },
  }),
})

// // Output CSS as string.
// typography.toString()

// // Or insert styles directly into the <head> (works well for client-only
// // JS web apps.
// typography.injectStyles()

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
