/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { ThemeProvider } from "styled-components"
import {
  createTheme,
  ThemeProvider as MThemeProvider,
} from "@material-ui/core/styles"
import themeForProvider from "./src/utilities/theme"

const theme = createTheme({
  palette: {
    primary: {
      main: "#00338D",
    },
    secondary: {
      main: "#EBB700",
    },
  },
  typography: {
    fontWeightLight: 300,
    h1: {
      fontSize: 36,
    },
    h2: {
      fontSize: 32,
    },
    h3: {
      fontSize: 28,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: 18,
    },
  },
})

export const wrapRootElement = ({ element }) => {
  return (
    <MThemeProvider theme={theme}>
      <ThemeProvider theme={themeForProvider}>{element}</ThemeProvider>
    </MThemeProvider>
  )
}
